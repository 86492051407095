import React from "react"
import Helmet from "react-helmet"
import PropTypes from "prop-types"
import SEO from "../SEO/SEO"


const Meta = props => {

  // make sure there's '/' before the uri
  const uri = props.uri.startsWith("/") ? props.uri : "/" + props.uri
  const params = props
  const url = `${process.env.SITE_URL + uri}`

  params.seo.url = url
  params.helmet.link = url

  return (
    <>
      <SEO {...params.seo} />
      <Helmet>
        <link {...params.helmet} />
      </Helmet>
    </>
  )
}

Meta.propTypes = {
  seo: PropTypes.shape({
    title: PropTypes.string.isRequired,
    desc: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
    img: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  }),
  helmet: PropTypes.shape({
    rel: PropTypes.string,
  }),
  uri: PropTypes.string.isRequired,
}

Meta.defaultProps = {
  seo: {
    title: null,
    desc: false,
    img: false,
  },
  helmet: {
    rel: "canonical",
  },
  uri: null,
}

export default Meta
