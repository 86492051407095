import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Helmet from "react-helmet"
import PropTypes from "prop-types"
import Facebook from "./Facebook"
import Twitter from "./Twitter"

const SEO = ({
  title,
  desc,
  schema,
  overrideDefaultSchema,
  img,
  imgAlt,
  url,
}) => {
  const { site, biz } = useStaticQuery(query)

  const {
    siteMetadata: {
      defaultUrl,
      // defaultImage,
      defaultTitle,
      defaultDescription,
      // headline,
      siteLanguage,
      ogLanguage,
      ogName,
      // author,
    },
  } = site

  const {
    description,
    email,
    // facebookHandle,
    // instagramHandle,
    logo,
    phone,
    twitterHandle,
  } = biz

  const seo = {
    title: title || defaultTitle,
    description: desc || defaultDescription,
    image:
      // if image url is provided and non-relative, don't prepend site url
      // pattern: "[subdomain].[domain].[tld]/"
      img && img.match(/[A-Za-z]{2,}\.[A-Za-z]{2,}\.[a-z]{3}\//)
        ? img
        : process.env.SITE_URL + img,
    imgAlt: imgAlt || desc || defaultDescription,
    url: url || defaultUrl,
  }

  const defaultSchema = {
    "@context": "http://schema.org",
    "@type": "Hotel",
    name: "Lakeside Lodge Clemson",
    description: description.description,
    address: {
      "@type": "PostalAddress",
      addressCountry: "US",
      addressLocality: "Seneca",
      addressRegion: "SC",
      postalCode: "29678",
      streetAddress: "13500 Clemson Blvd",
    },
    availableLanguage: "English",
    checkInTime: "14:00:00",
    checkOutTime: "11:00:00",
    currenciesAccepted: "USD",
    email: email,
    logo: logo.file.url,
    numberOfRooms: 189,
    openingHours: "Mo-Su",
    paymentAccepted: "Credit Card",
    petsAllowed: "http://schema.org/True",
    image: seo.image,
    priceRange: "$117 - $197",
    starRating: {
      "@type": "Rating",
      ratingValue: "4",
    },
    telephone: phone,
    url: process.env.SITE_URL,
  }

  // const defaultSchema = {
  //   "@context": "http://schema.org",
  //   "@type": "WebPage",
  //   url: defaultUrl,
  //   headline,
  //   inLanguage: siteLanguage,
  //   mainEntityOfPage: url,
  //   description: defaultDescription,
  //   name: defaultTitle,
  //   copyrightYear: new Date().getFullYear(),
  //   creator: {
  //     "@type": "Organization",
  //     name: author,
  //     sameAs: [
  //       `https://facebook.com/${facebookHandle}`,
  //       `https://instagram.com/${instagramHandle}`,
  //       `https://twitter.com/${twitterHandle}`,
  //     ],
  //     url: process.env.SITE_URL,
  //   },
  //   publisher: {
  //     "@type": "Organization",
  //     name: author,
  //     sameAs: [
  //       `https://facebook.com/${facebookHandle}`,
  //       `https://instagram.com/${instagramHandle}`,
  //       `https://twitter.com/${twitterHandle}`,
  //     ],
  //     url: process.env.SITE_URL,
  //   },
  //   datePublished: new Date(),
  //   image: {
  //     "@type": "ImageObject",
  //     url: `${defaultUrl}${defaultImage}`,
  //   },
  // }

  const renderStandardJsonLD = _ => {
    if (!overrideDefaultSchema || (overrideDefaultSchema && !schema)) {
      return (
        <script type="application/ld+json">
          {JSON.stringify(defaultSchema)}
        </script>
      )
    }
  }

  const renderCustomJsonLD = _ => {
    if (!schema) {
      return null
    }
    if (Array.isArray(schema)) {
      return schema.map((obj, index) => (
        <script key={index} type="application/ld+json">
          {JSON.stringify(obj)}
        </script>
      ))
    }
    return <script type="application/ld+json">{JSON.stringify(schema)}</script>
  }

  return (
    <>
      <Helmet title={seo.title}>
        <html lang={siteLanguage} />
        <meta name="description" content={seo.description} />
        <meta name="image" content={seo.image} />
        {renderStandardJsonLD()}
        {renderCustomJsonLD()}
      </Helmet>
      <Facebook
        alt={seo.imgAlt}
        desc={seo.description}
        image={seo.image}
        locale={ogLanguage}
        name={ogName}
        title={seo.title}
        type="website"
        url={(typeof window !== "undefined" && window.location.href) || seo.url}
      />
      <Twitter
        alt={seo.imgAlt}
        desc={seo.description}
        image={seo.image}
        title={seo.title}
        username={twitterHandle}
      />
    </>
  )
}

export default SEO

SEO.propTypes = {
  title: PropTypes.string,
  desc: PropTypes.string,
  img: PropTypes.string,
  imgAlt: PropTypes.string,
  overrideDefaultSchema: PropTypes.bool,
  schema: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.arrayOf(PropTypes.object),
  ]),
  node: PropTypes.object,
}

SEO.defaultProps = {
  title: null,
  desc: null,
  img: null,
  imgAlt: null,
  overrideDefaultSchema: false,
  schema: null,
  node: null,
}

const query = graphql`
  query SEO {
    site {
      siteMetadata {
        defaultUrl: siteUrl
        defaultImage: image
        defaultTitle: title
        defaultDescription: description
        headline
        siteLanguage
        ogLanguage
        ogName
      }
    }
    biz: contentfulHotel(name: { eq: "Lakeside Lodge Clemson" }) {
      description {
        description
      }
      email
      facebookHandle
      instagramHandle
      logo {
        file {
          url
        }
      }
      phone
      twitterHandle
    }
  }
`
